import * as React from 'react'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { NormalizedCacheObject } from '@apollo/client'
import { Analytics } from '@vercel/analytics/react'

import '@/config'

import('@/utils/route-change-animation')

import useScriptsDelayLoad from '@/components/head/scripts-delay-hook'
import pageVisibilityChangeHandler from '@/utils/browser/page-visibility-change-handler'
import useRestoreScrollHeight from '@/utils/browser/restore-scroll-height-hook'
import { initFiatCurrencies } from '@/service/currency/fetch-currencies'
import { ActionCableProvider } from '@/apollo-client/action-cable-provider'

const GlobalStyle = dynamic(() => import('../style/global-style'))
const Metas = dynamic(() => import('@/components/head/metas'))
const Layout = dynamic(() => import('@/components/layout'))

const CheckRedirectAboutPage = dynamic(
  () => import('@/components/shared/check-redirect-about-page'),
)

const I18nProvider = dynamic(() => import('@/service/locale/locale-provider'))
const ThirdParty = dynamic(() => import('@/components/third-party'))
const ApolloProvider = dynamic(() => import('../apollo-client/apollo-provider'))
const Toast = dynamic(() => import('@/components/common/toast'), { ssr: false })
const SignInModal = dynamic(() => import('@/components/shape/sign-in-modal'), { ssr: false })

function App({ Component, pageProps }: AppProps<{ initialApolloState?: NormalizedCacheObject }>) {
  useScriptsDelayLoad()
  useRestoreScrollHeight()

  React.useMemo(() => {
    typeof global.window !== 'undefined' && initFiatCurrencies()
  }, [])

  // 한시간 지난 상태에서 브라우저 탭이 전환되어 활성화된 경우 새로고침
  // 캐시 사용을 강제로 해제하기 위함
  React.useEffect(() => {
    const loadDatePlusOneHours = new Date()
    loadDatePlusOneHours.setHours(loadDatePlusOneHours.getHours() + 1)
    pageVisibilityChangeHandler((isVisibility) => {
      if (isVisibility && loadDatePlusOneHours < new Date()) {
        window.location.reload()
      }
    })
  }, [])

  return (
    <>
      <ActionCableProvider>
        <GlobalStyle />
        <ApolloProvider initialApolloState={pageProps?.initialApolloState}>
          <I18nProvider>
            <Metas />
            <ThirdParty>
              <Layout>
                <CheckRedirectAboutPage>
                  <Component {...pageProps} />
                  <SignInModal />
                </CheckRedirectAboutPage>
              </Layout>
            </ThirdParty>
          </I18nProvider>
        </ApolloProvider>
        <Toast />
        <Analytics />
      </ActionCableProvider>
    </>
  )
}

export default App
