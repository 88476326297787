import { ApolloLink } from '@apollo/client'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { Consumer } from '@rails/actioncable'
import { Operation } from '@apollo/client/link/core/types'

import getLink from './http-link'

interface Definition {
  kind: string
  operation?: string
}
const hasSubscriptionOperation = ({ query: { definitions } }: Operation): boolean => {
  return definitions.some(
    ({ kind, operation }: Definition) =>
      kind === 'OperationDefinition' && operation === 'subscription',
  )
}

export type ApiServerLinkProps = {
  cable?: Consumer | null
}

export default function apiServerLink({ cable }: ApiServerLinkProps): ApolloLink {
  if (typeof window !== 'undefined' && cable != null) {
    console.log('apiServerLink', { cable })
    return ApolloLink.split(
      hasSubscriptionOperation,
      new ActionCableLink({ cable }),
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'API2',
        getLink({ uri: process.env.NEXT_PUBLIC_WEB_BACK_SERVER2_URL as string }), // clientName === 'API2'
        getLink({ uri: process.env.NEXT_PUBLIC_WEB_BACK_SERVER_URL as string }), // clientName !== 'API2'
      ),
    )
  }
  return ApolloLink.split(
    (operation) => operation.getContext().clientName === 'API2',
    getLink({ uri: process.env.NEXT_PUBLIC_WEB_BACK_SERVER2_URL as string }), // clientName === 'API2'
    getLink({ uri: process.env.NEXT_PUBLIC_WEB_BACK_SERVER_URL as string }), // clientName !== 'API2'
  )
}
