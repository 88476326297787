import { makeVar, useReactiveVar } from '@apollo/client'
import * as React from 'react'
import { isEmpty } from 'lodash/fp'

import { getLocalStorage, setLocalStorage } from '@/utils/browser/localstorage'
import { LocalStorageEnum } from '@/constants/localstorage'
import {
  useSignInWithFundoraMutation,
  useUserPhoneVerificationStatusLazyQuery,
} from '@/gql/generated-api1'
import isExpired from '@/utils/jwt/is-expired'

export const fundoraAuthTokenStore = makeVar({ token: '' })

const setFundoraAuthTokenStore = (token: string) => {
  fundoraAuthTokenStore({ token })
}

export const getFundoraAuthToken = () => {
  return getLocalStorage<string>({ key: LocalStorageEnum.FundoraAuthToken })
}

export const setFundoraAuthToken = (newToken = '') => {
  setLocalStorage<string>({ key: LocalStorageEnum.FundoraAuthToken, value: newToken })
  setFundoraAuthTokenStore(newToken ?? '')
}

export const removeFundoraAuthToken = () => {
  setLocalStorage({ key: LocalStorageEnum.FundoraAuthToken, value: '' })
}

export const useFundoraSignInWithCICheck = () => {
  const [getVerificationStatus] = useUserPhoneVerificationStatusLazyQuery()
  const [signInFundora] = useSignInWithFundoraMutation()

  const signInFundoraWithCheck = React.useCallback(() => {
    getVerificationStatus().then(({ data }) => {
      const hasFundoraCI = data?.userPhoneVerificationStatus.fundoraUserPhoneVerification.isVerified
      if (!hasFundoraCI) return
      signInFundora()
        .then((response) => {
          const fundoraToken = response?.data?.signInWithFundora?.token
          if (isEmpty(fundoraToken)) return
          setFundoraAuthToken(fundoraToken)
        })
        .catch()
    })
  }, [getVerificationStatus, signInFundora])

  return [signInFundoraWithCheck]
}

export const useFundoraAuthTokenStore = () => {
  const { token } = useReactiveVar(fundoraAuthTokenStore)
  const [signInFundoraWithCheck] = useFundoraSignInWithCICheck()

  React.useEffect(() => {
    if (isEmpty(token)) {
      const localFundoraAuthToken = getFundoraAuthToken()
      if (!isEmpty(localFundoraAuthToken)) {
        setFundoraAuthTokenStore(localFundoraAuthToken)
      } else {
        signInFundoraWithCheck()
      }
    } else if (isExpired(token)) {
      signInFundoraWithCheck()
    }
  }, [signInFundoraWithCheck, token])

  return { token }
}
