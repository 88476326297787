import { createContext, useEffect, useState } from 'react'
import getConfig from 'next/config'
import { Consumer } from '@rails/actioncable'

interface ICable {
  cable: Consumer
}

const ActionCableContext = createContext<Consumer>({} as Consumer)

const { publicRuntimeConfig } = getConfig()
// actionCable Url

type Props = {
  children: JSX.Element | JSX.Element[]
}

const ActionCableProvider = ({ children }: Props) => {
  const [CableApp, setCableApp] = useState({} as ICable)

  const loadConsumer = async () => {
    const { createConsumer } = await import('@rails/actioncable')
    return createConsumer
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && CableApp.cable === undefined) {
      loadConsumer().then((createConsumer) => {
        const actionCableUrl = publicRuntimeConfig.subscriptionUrl
        const cable = createConsumer(actionCableUrl)
        console.log('useEffect', cable)
        setCableApp({
          cable,
        })
      })
    }
  }, [])

  return (
    <ActionCableContext.Provider value={CableApp.cable}>{children}</ActionCableContext.Provider>
  )
}

export { ActionCableContext, ActionCableProvider }
